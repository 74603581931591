<template>
  <cs-dialog-form
    :title="title"
    :model="formModel"
    :action="submit"
    width="436px"
  >
    <div v-for="(item, index2) in formModel.evaluates" :key="index2">
      <div class="text-center">
        <h1 style="font-size: 20px; color: #202121">{{ item.productName }}</h1>
        <img
          v-for="(star, index) in source.starContent"
          :key="index"
          class="cursor-pointer"
          :src="
            api.getPublicFile(
              `img/select-${item.starLevel > index ? 'on' : 'off'}.png`
            )
          "
          style="margin: 10px 14px 20px 14px; height: 36px"
          @click="starClick(item, index + 1)"
        />
      </div>
      <a-input
        v-model.trim="item.content"
        :maxLength="300"
        rows="4"
        type="textarea"
        :placeholder="source.starContent[item.starLevel - 1]"
      />
    </div>
  </cs-dialog-form>
</template>
<script>
export default {
  props: {
    data: {},
  },
  data() {
    return {
      formModel: {
        evaluates: [],
        ...this.data,
      },
      source: {
        starContent: [
          "糟糕透了",
          "不喜欢",
          "还行，凑合能用",
          "不错的软件，推荐下载",
          "相当给力，强烈推荐",
        ],
      },
    };
  },
  computed: {
    title() {
      return `用户评价`;
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      const { formModel } = this;
      const { id } = formModel;
      if (id) {
        this.api.service
          .fpc_oapi_order_selectOrderById({}, { id })
          .then(async ({ code, msg, data }) => {
            await this.api.toast({ code, msg }, 0);
            formModel.evaluates = data.products.map((ele) => {
              return {
                content: "",
                orderProId: ele.id,
                starLevel: 5,
                productName: ele.productName,
              };
            });
          });
      }
    },
    starClick(item, index) {
      item.starLevel = index;
    },
    async submit(formModel) {
      formModel.evaluates.forEach((ele) => {
        if (!ele.content) {
          ele.content = this.source.starContent[ele.starLevel - 1];
        }
      });
      return await this.api.service.fpc_oapi_evaluate_addOutEvaluate(
        formModel,
        {}
      );
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-modal-body {
  padding: 16px;
  .ant-modal-body-content {
    padding: 16px;
    background-color: #f3f9f9;
    background-image: url(../../../public/img/evaluate-bg.png);
    background-repeat: no-repeat;
    background-position: right;
    .ant-input {
      border: 0;
    }
  }
}
</style>
